<template>
  <b-row>
    <b-col v-show="noData" cols="12">
      <b-card class="d-flex align-items-center justify-content-center">
        <font-awesome-icon icon="exclamation-triangle" class="mr-2" />
        <b>ไม่พบข้อมูลที่ค้นหา</b>
      </b-card>
    </b-col>

    <b-col v-show="!noData" cols="12">
      <survey-chart v-if="!isFetching" :survey_answers="surveyAnswers">
      </survey-chart>

      <div class="card__flex card card-box mb-3">
        <div class="card-header pr-2">
          <div class="card-header--title">
            <small>{{ title }}</small>
          </div>
          <div class="card-header--actions">
            <slot name="header-actions"></slot>
          </div>
        </div>

        <div class="card-body p-0">
          <base-table
            v-if="!isFetching"
            ref="overviewTable"
            :provider="overviewProvider"
            :fields="overviewTableFields"
            :action-label="actionLabel"
            :is-busy="isFetching"
            :current-page.sync="overviewCurrentPage"
            :number-of-pages="overviewNumberOfPages"
            :sort-by.sync="overviewSortBy"
            :sort-desc.sync="overviewSortDesc"
            :per-page="10"
            class="mb-3"
          >
            <template #thead-top>
              <b-tr v-if="Object.keys(overviewSurveyTopicFields).length">
                <b-th
                  :colspan="overviewFields.length + 1"
                  style="background-color: white"
                  ><span class="sr-only">Main columns</span></b-th
                >

                <template
                  v-for="(
                    overviewSurveyTopicHeader, index
                  ) in overviewSurveyTopicHeaders"
                >
                  <b-th
                    v-if="overviewSurveyTopicHeader.readonly"
                    :key="`topic-header-${index}`"
                    :colspan="overviewSurveyTopicHeader.count"
                    style="background-color: white"
                    ><span class="sr-only"></span
                  ></b-th>

                  <b-th
                    v-else
                    :key="`topic-header-${index}`"
                    :colspan="overviewSurveyTopicHeader.count"
                    class="text-center"
                    style="
                      border-left: 1px solid #dcdef1;
                      border-right: 1px solid #dcdef1;
                    "
                  >
                    {{ overviewSurveyTopicHeader.label }}
                  </b-th>
                </template>
              </b-tr>

              <b-tr v-if="Object.keys(questionChoiceMapping).length">
                <b-th
                  :colspan="overviewFields.length + 1"
                  style="background-color: white"
                  ><span class="sr-only">Main columns</span></b-th
                >

                <b-th
                  v-for="mapping in questionChoiceMapping"
                  :key="`question-choice-${mapping.label}`"
                  :colspan="mapping.count"
                  class="text-center"
                  style="border-left: 1px solid #dcdef1; min-width: 200px"
                >
                  {{ mapping.label }}
                </b-th>
              </b-tr>
            </template>

            <template
              v-for="valueField in valueFields"
              v-slot:[`cell(${valueField})`]="{ item }"
            >
              <div :key="`template-${valueField}`">
                {{
                  item[`${valueField}`]
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </div>
            </template>
          </base-table>

          <base-table
            ref="table"
            :provider="fetch"
            :fields="tableFields"
            :action-label="actionLabel"
            :is-busy.sync="isFetching"
            :current-page.sync="currentPage"
            :number-of-pages="numberOfPages"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="10"
          >
            <template #thead-top>
              <b-tr v-if="Object.keys(surveyTopicFields).length">
                <b-th
                  :colspan="fields.length + 1"
                  style="background-color: white"
                  ><span class="sr-only">Main columns</span></b-th
                >

                <template
                  v-for="(surveyTopicHeader, index) in surveyTopicHeaders"
                >
                  <b-th
                    v-if="surveyTopicHeader.readonly"
                    :key="`topic-header-${index}`"
                    :colspan="surveyTopicHeader.count"
                    style="background-color: white"
                    ><span class="sr-only"></span
                  ></b-th>

                  <b-th
                    v-else
                    :key="`topic-header-${index}`"
                    :colspan="surveyTopicHeader.count"
                    class="text-center"
                    style="
                      border-left: 1px solid #dcdef1;
                      border-right: 1px solid #dcdef1;
                    "
                  >
                    {{ surveyTopicHeader.label }}
                  </b-th>
                </template>
              </b-tr>

              <b-tr v-if="Object.keys(questionChoiceMapping).length">
                <b-th
                  :colspan="fields.length + 1"
                  style="background-color: white"
                  ><span class="sr-only">Main columns</span></b-th
                >

                <b-th
                  v-for="mapping in questionChoiceMapping"
                  :key="`question-choice-${mapping.label}`"
                  :colspan="mapping.count"
                  class="text-center"
                  style="border-left: 1px solid #dcdef1; min-width: 300px"
                >
                  {{ mapping.label }}
                </b-th>
              </b-tr>

              <!-- <b-tr v-if="Object.keys(questionChoiceMapping).length">
            <b-th :colspan="fields.length + 1" style="background-color: white"
              ><span class="sr-only">Main columns</span></b-th
            >

            <b-th
              v-for="mapping in questionChoiceMapping"
              :key="`question-choice-${mapping.label}`"
              :colspan="mapping.count"
              class="text-center"
              style="border-left: 1px solid #dcdef1"
            >
              {{ mapping.label }}
            </b-th>
          </b-tr>

          <b-tr v-else-if="Object.keys(surveyTopicFields).length">
            <b-th :colspan="fields.length + 1" style="background-color: white"
              ><span class="sr-only">Main columns</span></b-th
            >

            <b-th
              v-for="surveyTopicField in surveyTopicFields"
              :key="`topic-${surveyTopicField.label}`"
              :colspan="surveyTopicField.count"
              class="text-center"
              style="border-left: 1px solid #dcdef1"
            >
              {{ surveyTopicField.label }}
            </b-th>
          </b-tr> -->
            </template>

            <template
              v-for="valueField in valueFields"
              v-slot:[`cell(${valueField})`]="{ item }"
            >
              <div :key="`template-${valueField}`">
                {{
                  item[`${valueField}`]
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </div>
            </template>
          </base-table>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { orderBy } from "lodash";
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, Report, Survey } from "../../models";
import BaseTable from "./Base";
import { map, mapValues, merge } from "lodash";
import SurveyChart from "../chart/Survey";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "เพิ่ม/แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    surveyId: {
      type: Number,
      default: null,
    },
    survey: {
      type: Survey,
    },
  },

  components: {
    BaseTable,
    SurveyChart,
  },

  data() {
    return {
      isFetching: false,
      noData: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      latestsortBy: "",
      latestsortDesc: false,
      sortBy: "",
      sortDesc: false,
      overviewCurrentPage: parseInt(this.$route.query.page) || 1,
      overviewNumberOfPages: 1,
      latestOverviewSortBy: "",
      latestOverviewSortDesc: false,
      overviewSortBy: "",
      overviewSortDesc: false,
      // fieldsOverview: [],
      tableData: [],
      overviewData: [],
      tableTemplate: {},
      questionFields: [],
      questionChoiceFields: [],
      questionChoiceMapping: {},
      surveyTopicFields: {},
      surveyAnswers: [],
      valueFields: [],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    // surveyTopicsTabelHeaders() {
    //   return Object.values(this.surveyTopicFields);
    // },

    tableFields() {
      let fields = [...this.fields];

      if (this.hasSomeChoiceQuestion) {
        fields = [...fields, ...this.questionChoiceFields];
      } else {
        fields = [
          ...fields,
          ...this.questionFields.filter(
            ({ questionType }) => questionType !== "text"
          ),
        ];
      }

      return fields;
    },

    overviewFields() {
      let fields = [];

      fields.push({
        key: "m_headquarters_name",
        label: "ภาค",
        sortable: true,
        thStyle: {
          minWidth: "100px",
        },
      });

      if (this.params && this.params.mHeadquarterId) {
        fields.push({
          key: "m_division_name",
          label: "จังหวัด",
          sortable: true,
          thStyle: {
            minWidth: "125px",
          },
        });
      }

      if (this.params && this.params.mDivisionId) {
        fields.push({
          key: "m_station_name",
          label: "สถานี",
          sortable: true,
          thStyle: {
            minWidth: "150px",
          },
        });
      }

      return fields;
    },

    // overviewType() {
    //   let type = "ภาค";

    //   if (this.params && this.params.mHeadquarterId) {
    //     type = "จังหวัด";
    //   }

    //   if (this.params && this.params.mDivisionId) {
    //     type = "สถานี";
    //   }

    //   return type;
    // },

    hasSomeChoiceQuestion() {
      return this.questionFields.some(
        ({ questionType } = {}) => questionType === "choice"
      );
    },

    overviewTableFields() {
      let fields = [...this.overviewFields];

      if (this.hasSomeChoiceQuestion) {
        fields = [...fields, ...this.questionChoiceFields];
      } else {
        fields = [
          ...fields,
          ...this.questionFields.filter(
            ({ questionType }) => questionType !== "text"
          ),
        ];
      }

      return fields;
    },

    overviewSurveyTopicFields() {
      let overviewSurveyTopicFields = {};

      for (const key in this.surveyTopicFields) {
        if (Object.hasOwnProperty.call(this.surveyTopicFields, key)) {
          const surveyTopicField = this.surveyTopicFields[key];

          if (surveyTopicField.label !== "ข้อเสนอแนะ") {
            overviewSurveyTopicFields[key] = { ...surveyTopicField };
          }
        }
      }

      return overviewSurveyTopicFields;
    },

    overviewSurveyTopicHeaders() {
      return this.getHeaders(this.overviewSurveyTopicFields);
    },

    surveyTopicHeaders() {
      return this.getHeaders(this.surveyTopicFields);
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    extractSurveyAnswers() {
      const { survey = {}, tableTemplate = {} } = this;

      const questions = {};

      for (const questionId in tableTemplate) {
        if (Object.hasOwnProperty.call(tableTemplate, questionId)) {
          const question = tableTemplate[questionId];

          if (question.question_type === "text") {
            continue;
          }

          const surveyAnswerData = this.extractQuestion(question);

          if (!question.surveyTopicId) {
            this.$set(questions, question.question_no, {
              ...surveyAnswerData,
            });
          } else {
            const { survey_topic } = question;

            if (!questions[survey_topic.survey_topic_no]) {
              this.$set(questions, survey_topic.survey_topic_no, {
                survey_topic: survey_topic.survey_topic_no,
                questions: [],
              });
            }

            questions[survey_topic.survey_topic_no].questions.push({
              ...surveyAnswerData,
            });
          }
        }
      }

      this.surveyAnswers = [{ ...survey, questions }];
    },

    extractQuestion(question) {
      if (question.question_type === "choice") {
        question = this.extractQuestionChoice(question);
      }

      if (question.question_type === "rating") {
        question = this.extractQuestionRating(question);
      }

      return question;
    },

    extractQuestionChoice(question) {
      const { overviewData = [] } = this;
      const { questionId, choices = {} } = question;

      let choice_detail = [],
        totalCount = 0;

      for (const choiceId in choices) {
        if (Object.hasOwnProperty.call(choices, choiceId)) {
          const choice = choices[choiceId];

          const count_answer = overviewData
            .map((record) => record[`questionChoice_${choiceId}`])
            .reduce((acc, count) => {
              if (count && count !== "-") {
                acc = acc + parseInt(count);
              }

              return acc;
            }, 0);

          totalCount += count_answer;

          choice_detail.push({
            ...choice,
            id: choice.questionChoiceId,
            answer: [
              {
                choice_answer: choice.question_choice_no,
                count_answer,
                questionChoiceId: choice.questionChoiceId,
              },
            ],
          });
        }
      }

      choice_detail = choice_detail.map((record) => {
        const choiceCountAnswer = record.answer[0].count_answer;

        return {
          ...record,
          answer_percentage: parseFloat(
            ((choiceCountAnswer / totalCount) * 100).toFixed(2)
          ),
        };
      });

      const resAns = choice_detail.map((record) => {
        const { answer_percentage } = record;
        const choiceCountAnswer = record.answer[0].count_answer;

        return {
          ...choiceCountAnswer,
          answer_percentage,
        };
      });

      this.$set(question, "choice_detail", choice_detail);

      this.$set(question, "resAns", resAns);

      return { ...question, id: questionId };
    },

    extractQuestionRating(question) {
      const { overviewData = [] } = this;
      const { questionId } = question;

      let countRating = 0;

      let totalRating = overviewData
        .map((record) => record[`question_${questionId}`])
        .reduce((acc, rating) => {
          if (rating && rating !== "-") {
            acc = acc + parseFloat(rating);
            countRating += 1;
          }

          return acc;
        }, 0);

      const avgRating = parseFloat((totalRating / countRating).toFixed(2));

      const resAns = [
        {
          avg_rating: avgRating,
          questionId,
        },
      ];

      this.$set(question, "resAns", resAns);

      return { ...question, id: questionId };
    },

    getHeaders(surveyTopicFields) {
      const headers = [],
        topicIndice = {};

      let {
        questionFields = [],
        questionChoiceFields = [],
        questionChoiceMapping = {},
      } = this;

      questionFields = questionFields.filter(
        ({ questionType }) => questionType !== "text"
      );

      questionFields.forEach((questionField) => {
        if (!questionField.surveyTopicId) {
          headers.push({
            readonly: true,
            count:
              questionField.questionType === "choice"
                ? questionChoiceMapping[questionField.questionId].count
                : 1,
          });
        } else {
          if (!topicIndice[questionField.surveyTopicId]) {
            const record =
              surveyTopicFields[`surveyTopic${questionField.surveyTopicId}`];

            const filteredQuestionChoices = questionChoiceFields.filter(
              ({ surveyTopicId }) => {
                return surveyTopicId == questionField.surveyTopicId;
              }
            );

            if (filteredQuestionChoices.length) {
              this.$set(record, "count", filteredQuestionChoices.length);
            }

            headers.push({ ...record });

            this.$set(topicIndice, questionField.surveyTopicId, true);
          }
        }
      });

      return headers;
    },

    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.overviewCurrentPage = 1;
      this.overviewNumberOfPages = 1;

      this.latestSortBy = null;
      this.latestSortDesc = null;

      this.overviewLatestSortBy = null;
      this.overviewLatestSortDesc = null;

      this.overviewData = [];
      this.tableData = [];
      this.surveyAnswers = [];

      this.refresh();
    },

    async fetch(ctx) {
      if (!this.surveyId) return;

      let data = [],
        overviewData = [];

      const params = {},
        additionalParams = {
          id: this.surveyId,
          withUser: true,
          withSchool: true,
        };

      this.fieldsOverview = [...this.fields];

      // if (!ctx.downloadAll) {
      //   this.$set(params, "limit", ctx.perPage);
      //   this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
      // }

      // if (!ctx.downloadAll) {
      //   this.$set(params, "limit", ctx.perPage);
      //   this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
      // }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.fiscalYearFrom) {
        this.$set(
          additionalParams,
          "fiscalYearFrom",
          this.params.fiscalYearFrom
        );
      }

      if (this.params && this.params.termFrom) {
        this.$set(additionalParams, "termFrom", this.params.termFrom);
      }

      if (this.params && this.params.fiscalYearTo) {
        this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
      }

      if (this.params && this.params.termTo) {
        this.$set(additionalParams, "termTo", this.params.termTo);
      }

      if (this.params && this.params.startDate) {
        this.$set(additionalParams, "startDate", this.params.startDate);
      }

      if (this.params && this.params.endDate) {
        this.$set(additionalParams, "endDate", this.params.endDate);
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      // if (this.sortBy) {
      //   this.$set(additionalParams, "order", this.sortBy);
      //   this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      // }

      if (!this.tableData.length) {
        this.noData = false;
        this.isFetching = true;

        try {
          const promise = await Report.api().getSurveySummary(
            { ...params, ...additionalParams },
            { save: false }
          );

          let { teachingDares: responseData = [], template = {} } =
            promise.response.data;

          if (!responseData.length) {
            this.noData = true;
          }

          this.tableTemplate = { ...(template[this.surveyId] || {}) };

          let questionFields = [],
            questionChoiceFields = [],
            valueFields = [];

          this.surveyTopicFields = {};
          this.questionChoiceMapping = {};

          if (Object.keys(this.tableTemplate).length) {
            Object.values(this.tableTemplate).forEach((template) => {
              if (template.surveyTopicId) {
                const topicKey = `surveyTopic${template.surveyTopicId}`;

                if (!this.surveyTopicFields[topicKey]) {
                  this.$set(this.surveyTopicFields, topicKey, {
                    label: `${template.survey_topic.survey_topic_no}. ${template.survey_topic.survey_topic_title}`,
                    count: 1,
                  });
                } else {
                  this.$set(
                    this.surveyTopicFields[topicKey],
                    "count",
                    this.surveyTopicFields[topicKey].count + 1
                  );
                }
              }

              const { choices = {} } = template;
              const choiceLength = Object.keys(choices).length;

              if (choiceLength) {
                if (!this.questionChoiceMapping[template.questionId]) {
                  let questionLabel = `${template.question_no}. ${template.question_title}`;

                  if (template.surveyTopicId) {
                    questionLabel = `${template.survey_topic.survey_topic_no}.${questionLabel}`;
                  }

                  this.$set(this.questionChoiceMapping, template.questionId, {
                    label: questionLabel,
                    count: choiceLength,
                  });
                }

                for (const choiceId in choices) {
                  if (Object.hasOwnProperty.call(choices, choiceId)) {
                    const choice = choices[choiceId];

                    let choiceLabelNo = `${template.question_no}.${choice.question_choice_no}`;

                    if (template.surveyTopicId) {
                      choiceLabelNo = `${template.survey_topic.survey_topic_no}.${choiceLabelNo}`;
                    }

                    const label = choice.question_choice_title
                      .replace(/\((.+?)\)/g, "")
                      .trim();

                    valueFields.push(`questionChoice_${choiceId}`);

                    questionChoiceFields.push({
                      questionId: template.questionId,
                      questionChoiceId: choiceId,
                      key: `questionChoice_${choiceId}`,
                      label: `${choiceLabelNo}. ${label}`,
                      surveyTopicId: template.surveyTopicId,
                      surveyTopic: template.survey_topic,
                      thStyle: {
                        minWidth: "100px",
                        borderLeft: "1px solid #dcdef1",
                      },
                      tdClass: {
                        "text-pre-wrap": true,
                        "text-right": true,
                        td__survey: true,
                      },
                      sortable: true,
                    });
                    // this.$set(questionChoiceFields, choiceKey, {
                    //   label: choice.question_choice_title,
                    //   count: 1,
                    // });
                  }
                }
              }

              let questionLabel = `${template.question_no}. ${template.question_title}`;

              if (template.surveyTopicId) {
                questionLabel = `${template.survey_topic.survey_topic_no}.${questionLabel}`;
              }

              valueFields.push(`question_${template.questionId}`);

              questionFields.push({
                questionId: template.questionId,
                key: `question_${template.questionId}`,
                label: questionLabel,
                questionType: template.question_type,
                surveyTopicId: template.surveyTopicId,
                surveyTopic: template.survey_topic,
                thStyle: {
                  minWidth: "125px",
                },
                tdClass: {
                  "text-pre-wrap": true,
                  "text-right": template.question_type !== "text",
                  td__survey: true,
                },
                sortable: true,
              });
            });
          }

          this.questionFields = [...questionFields];
          this.questionChoiceFields = [...questionChoiceFields];
          this.valueFields = [...valueFields];

          this.tableData = responseData.map((record) => {
            let user_name = "",
              position_name = "-";

            if (record.user_profile) {
              const userRank = record.user_profile.m_rank;

              user_name = `${record.user_profile.first_name} ${record.user_profile.last_name}`;

              if (userRank) {
                user_name = `${userRank.m_rank_name} ${user_name}`;
              }

              position_name = record.user_profile.position_name;
            }

            let yearTerm = record.fiscal_year || "";

            if (record.term) {
              yearTerm += `/${record.term}`;
            }

            let classRoom = "";

            if (record.m_class) {
              classRoom += record.m_class.m_class_name;

              const roomNumber = parseInt(record.room);

              if (!isNaN(roomNumber)) {
                classRoom += `/${roomNumber}`;
              }
            }

            let surveyResult = {};

            if (record.result) {
              const { survey = {} } = record.result;

              surveyResult = {
                ...surveyResult,
                ...Object.values(survey).reduce(
                  (
                    acc,
                    {
                      questionId,
                      question_type,
                      surveyTopicId = null,
                      value,
                    } = {}
                  ) => {
                    let resultRecord = {};

                    if (question_type === "text") {
                      const splitttedValue = `${value}`
                        .split(/\n/gi)
                        .filter(Boolean);

                      if (splitttedValue.length) {
                        value = splitttedValue
                          .map((value) => `- ${value}`)
                          .join("\n");
                      }
                    }

                    if (question_type === "choice") {
                      for (const choiceId in value) {
                        if (Object.hasOwnProperty.call(value, choiceId)) {
                          const choiceValue = value[choiceId];

                          const choiceKey = `questionChoice_${choiceId}`;

                          this.$set(resultRecord, choiceKey, choiceValue);
                        }
                      }
                    } else {
                      this.$set(
                        resultRecord,
                        `question_${questionId}`,
                        value ? value : "-"
                      );
                    }

                    if (surveyTopicId) {
                      this.$set(
                        resultRecord,
                        `surveyTopic${template.surveyTopicId}`,
                        true
                      );
                    }

                    return {
                      ...acc,
                      ...resultRecord,
                    };
                  },
                  {}
                ),
              };
            }

            // let isPlan = "ไม่สำเร็จ";

            // if (record.isPlan && record.isPlan === "ทำแผนการสอน") {
            //   isPlan = "สำเร็จ";
            // }

            // let station = null,
            //   division = null,
            //   headquarter = null;

            // if (record.m_station) {
            //   station = record.m_station.m_station_name;

            //   if (record.m_station.m_division) {
            //     division = record.m_station.m_division.m_division_name;

            //     if (record.m_station.m_division.m_headquarter) {
            //       headquarter =
            //         record.m_station.m_division.m_headquarter
            //           .m_headquarters_name;
            //     }
            //   }
            // }

            return {
              ...record,
              ...surveyResult,
              plan_student: record.plan_student || 0,
              actual_student: record.actual_student || 0,
              // teaching_plan_count: record.teaching_plan_count || 0,
              teaching_plan_count: "13",
              room: record.room || "-",
              fiscal_year: record.fiscal_year || "-",
              class: record.m_class ? record.m_class.m_class_name : null,
              school: record.m_school ? record.m_school.m_school_name : null,
              classRoom,
              yearTerm,
              // station,
              // division,
              // headquarter,
              amphur:
                record.m_school && record.m_school.m_amphur
                  ? record.m_school.m_amphur.m_amphur_name
                  : null,
              province:
                record.m_school && record.m_school.m_province
                  ? record.m_school.m_province.m_province_name
                  : null,
              updated_at: record.updatedAt
                ? this.$_formatDateMixin_formatNumeric(record.updatedAt)
                : "-",
              created_at: record.createdAt
                ? this.$_formatDateMixin_formatNumeric(record.createdAt)
                : "-",
              // isPlan,
              user_name,
              position_name,
            };
          });

          let {
            // teachingDares: responseData = [],
            overview: responseOverview = {},
            template: responseTemplate = {},
          } = promise.response.data;

          let templateSurvey = Object.values(responseTemplate);

          templateSurvey = templateSurvey[0];

          let questionFormData = {};

          const templateSurveyKeys = Object.keys(templateSurvey);

          templateSurveyKeys.forEach((key) => {
            const template = templateSurvey[key];

            this.$set(questionFormData, `question_${template.questionId}`, "-");
          });

          map(responseOverview, (record) => {
            let formData = {},
              surveyAnswer = {};

            // let station = null,
            //   division = null,
            //   headquarter = null;

            if (record.m_headquarters_name) {
              /**มีข้อมูล ภาค ...*/
              const { m_headquarters_name } = record;

              this.fieldsOverview.push({
                key: "m_headquarters_name",
                label: "จังหวัด",
                sortable: true,
              });

              let headQuarterRecord = { ...formData, m_headquarters_name };

              this.$set(formData, "m_headquarters_name", m_headquarters_name);

              if (record.m_divisions) {
                /**มีข้อมูล ภาค จังหวัด ...*/
                this.fieldsOverview.push({
                  key: "m_division_name",
                  label: "จังหวัด",
                  sortable: true,
                });

                mapValues(record.m_divisions, (m_division) => {
                  const { m_division_name } = m_division;

                  // division = m_division.m_division_name;
                  let divisionRecord = { ...formData, m_division_name };

                  this.$set(formData, "m_division_name", m_division_name);

                  if (m_division.m_stations) {
                    /**มีข้อมูล ภาค จังหวัด สถานี ...*/
                    this.fieldsOverview.push({
                      key: "m_station_name",
                      label: "สถานี",
                      sortable: true,
                    });

                    mapValues(m_division.m_stations, (m_station) => {
                      const { m_station_name } = m_station;

                      // station = m_station.m_station_name;

                      let stationRecord = { ...formData, m_station_name };

                      if (m_station.survey) {
                        surveyAnswer = { ...m_station.survey };

                        const keys = Object.keys(surveyAnswer);

                        keys.forEach((key) => {
                          const template = templateSurvey[key],
                            answer = surveyAnswer[key],
                            isSurveyData = merge(answer, template);

                          if (isSurveyData.question_type === "choice") {
                            for (const choiceId in isSurveyData.value) {
                              if (
                                Object.hasOwnProperty.call(
                                  isSurveyData.value,
                                  choiceId
                                )
                              ) {
                                const choiceValue =
                                  isSurveyData.value[choiceId];

                                const choiceKey = `questionChoice_${choiceId}`;

                                this.$set(
                                  stationRecord,
                                  choiceKey,
                                  choiceValue
                                );
                              }
                            }
                          } else {
                            this.$set(
                              stationRecord,
                              `question_${isSurveyData.questionId}`,
                              isSurveyData.value ? isSurveyData.value : "-"
                            );
                          }

                          if (isSurveyData.surveyTopicId) {
                            this.$set(
                              stationRecord,
                              `surveyTopic${isSurveyData.surveyTopicId}`,
                              true
                            );
                          }
                        });
                      }

                      overviewData.push(stationRecord);
                    });
                  } else {
                    /**มีข้อมูล ภาค จังหวัด สองอย่าง*/
                    if (m_division.survey) {
                      surveyAnswer = { ...m_division.survey };

                      const keys = Object.keys(surveyAnswer);
                      keys.forEach((key) => {
                        const template = templateSurvey[key],
                          answer = surveyAnswer[key],
                          isSurveyData = merge(answer, template);

                        if (isSurveyData.question_type === "choice") {
                          for (const choiceId in isSurveyData.value) {
                            if (
                              Object.hasOwnProperty.call(
                                isSurveyData.value,
                                choiceId
                              )
                            ) {
                              const choiceValue = isSurveyData.value[choiceId];

                              const choiceKey = `questionChoice_${choiceId}`;

                              this.$set(divisionRecord, choiceKey, choiceValue);
                            }
                          }
                        } else {
                          this.$set(
                            divisionRecord,
                            `question_${isSurveyData.questionId}`,
                            isSurveyData.value ? isSurveyData.value : "-"
                          );
                        }

                        if (isSurveyData.surveyTopicId) {
                          this.$set(
                            divisionRecord,
                            `surveyTopic${isSurveyData.surveyTopicId}`,
                            true
                          );
                        }
                      });
                    }

                    overviewData.push(divisionRecord);
                  }
                });
              } else {
                /**มีข้อมูล ภาค อย่างเดียว */
                if (record.survey) {
                  surveyAnswer = { ...record.survey };

                  const keys = Object.keys(surveyAnswer);

                  keys.forEach((key) => {
                    const template = templateSurvey[key],
                      answer = surveyAnswer[key],
                      isSurveyData = merge(answer, template);

                    if (isSurveyData.question_type === "choice") {
                      for (const choiceId in isSurveyData.value) {
                        if (
                          Object.hasOwnProperty.call(
                            isSurveyData.value,
                            choiceId
                          )
                        ) {
                          const choiceValue = isSurveyData.value[choiceId];

                          const choiceKey = `questionChoice_${choiceId}`;

                          this.$set(headQuarterRecord, choiceKey, choiceValue);
                        }
                      }
                    } else {
                      this.$set(
                        headQuarterRecord,
                        `question_${isSurveyData.questionId}`,
                        isSurveyData.value ? isSurveyData.value : "-"
                      );
                    }

                    if (isSurveyData.surveyTopicId) {
                      this.$set(
                        headQuarterRecord,
                        `surveyTopic${isSurveyData.surveyTopicId}`,
                        true
                      );
                    }
                  });
                }

                overviewData.push(headQuarterRecord);
              }
            }
          });

          this.overviewData = [...overviewData];

          this.extractSurveyAnswers();

          this.numberOfPages = Math.ceil(this.tableData.length / ctx.perPage);

          this.overviewNumberOfPages = Math.ceil(
            this.overviewData.length / ctx.perPage
          );
        } catch (error) {
          console.log(error);
          this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
        } finally {
          this.isFetching = false;
        }
      }

      // if (!ctx.downloadAll) {
      //   this.numberOfPages = numberOfPages;
      // }

      if (this.tableData.length) {
        if (
          this.sortBy &&
          (this.sortBy !== this.latestSortBy ||
            this.sortDesc !== this.latestSortDesc)
        ) {
          this.latestSortBy = this.sortBy;
          this.latestSortDesc = this.sortDesc;

          this.tableData = orderBy(
            this.tableData,
            [this.sortBy],
            [this.sortDesc ? "desc" : "asc"]
          );
        }

        const indexStart = (ctx.currentPage - 1) * ctx.perPage;

        const endIndex = indexStart + ctx.perPage;

        data = this.tableData.slice(indexStart, endIndex);
      }

      // return dataOverview;
      return data;
    },

    async overviewProvider(ctx) {
      if (
        this.overviewSortBy &&
        (this.overviewSortBy !== this.latestOverviewSortBy ||
          this.overviewSortDesc !== this.latestOverviewSortDesc)
      ) {
        this.latestOverviewSortBy = this.overviewSortBy;
        this.latestOverviewSortDesc = this.overviewSortDesc;

        this.overviewData = orderBy(
          this.overviewData,
          [this.overviewSortBy],
          [this.overviewSortDesc ? "desc" : "asc"]
        );
      }

      const indexStart = (ctx.currentPage - 1) * ctx.perPage;
      const endIndex = indexStart + ctx.perPage;

      return this.overviewData.length
        ? this.overviewData.slice(indexStart, endIndex)
        : [];
    },

    onSeeDetail(item) {
      this.$emit("seeDetail", item);
    },

    editTeaching(item) {
      this.$emit("edit", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>

<style lang="scss">
.td__survey {
  border-left: 1px solid #dcdef1;
}
</style>

<template>
  <page-content>
    <page-title :heading="surveyPageTitle">
      <template v-slot:right>
        <b-button
          :disabled="!enable"
          variant="danger"
          @click="showExportOption = true"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </b-button>

        <export-modal v-model="isExporting" />
        <!-- <download-excel
          class="btn btn-danger cursor-pointer"
          :fields="export_fields"
          :fetch="fetchExport"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </download-excel> -->
      </template>
    </page-title>

    <b-row class="mb-3">
      <b-col cols="12">
        <filter-fiscal-year-term
          :key="`filter-fiscal-year-term-${surveyId}`"
          v-model="formFiscalYearTerm"
        >
        </filter-fiscal-year-term>
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-master
          :key="`filter-master-${surveyId}`"
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true, room: true }"
        >
        </filter-master>

        <!-- <filter-master
          v-model="formfilter"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master> -->
      </b-col>

      <b-col cols="12" class="mt-2 mb-2">
        <filter-date-between
          :key="`filter-date-between-${surveyId}`"
          v-model="filterDateBetween"
        >
          <!-- <template #before>
            <b-col cols="12" lg="4" class="px-2">
              <label class="font-weight-bold ft-s-14" for="isPlan"
                >ผลการบันทึกการสอน</label
              >

              <v-select
                id="isPlan"
                v-model="isPlan"
                :clearable="isPlan !== defaultValue"
                :options="planStatuses"
                @input="onInput($event, 'isPlan')"
              ></v-select>
            </b-col>
          </template> -->
        </filter-date-between>
      </b-col>

      <b-col cols="12" class="mt-2">
        <div class="d-flex flex-row flex-wrap align-items-end">
          <b-col sm="8" md="6" class="px-2">
            <b-form-group class="mb-0">
              <b-input-group class="input-group-seamless">
                <b-form-input
                  placeholder="ค้นหารายชื่อครูตำรวจแดร์หรือโรงเรียน"
                  v-model="keyword"
                  @keyup.enter="onKeywordSearched"
                ></b-form-input>

                <b-input-group-text slot="prepend">
                  <font-awesome-icon class="mx-auto" icon="search" />
                </b-input-group-text>

                <b-input-group-text v-show="keyword.length" slot="append">
                  <a href="javascript:void(0)" @click="clearKeyword">
                    <font-awesome-icon class="mx-auto" icon="window-close" />
                  </a>
                </b-input-group-text>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col sm="4" md="2" class="px-2 mt-4 mt-sm-0">
            <b-button
              variant="primary"
              block
              size="sm"
              @click="onKeywordSearched"
              >ค้นหา</b-button
            >
          </b-col>
        </div>
      </b-col>
    </b-row>

    <!-- <b-row v-if="isFetching" align-h="center">
      <b-col cols="12">
        <b-card>
          <loading
            style="height: 250px"
            message="กำลังดาวน์โหลดสรุปภาพรวมผลการประเมิน"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <survey-chart :survey_answers="survey_answers" class="mt-4">
        </survey-chart>
      </b-col>
    </b-row> -->

    <b-row>
      <b-col cols="12">
        <report-survey-table
          v-if="enable"
          ref="table"
          :key="`report-survey-${surveyId}`"
          class="mt-4"
          :survey-id="surveyId"
          :survey="survey"
          :fields="fields"
          :params="tableParams"
          action-label=""
          :show-action="false"
          :see-detail="true"
          :show-delete="false"
          @delete="onDelete"
        />

        <b-card v-else class="text-center">
          คลิ๊ก <b>"ค้นหา"</b> เพื่อเริ่มดาว์นโหลดข้อมูล
        </b-card>
      </b-col>
    </b-row>

    <option-modal
      :key="`option-modal-${surveyId}`"
      title="เลือกรูปแบบการนำออกข้อมูล"
      name="exportOverviewOption"
      :options="exportOptions"
      v-model="showExportOption"
      @selected:type="exportReport"
    >
    </option-modal>
  </page-content>
</template>

<script>
import reportMixin from "../../mixins/reportMixin";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";
import { sortBy, groupBy } from "lodash";
// import vSelect from "vue-select";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
// import CreateOrUpdateClass from "../../components/modal/CreateOrUpdateClass";
import ReportSurveyTable from "../../components/table/ReportSurvey";
// import SurveyChart from "../../components/chart/Survey";
import FilterMaster from "../../components/form/FilterMaster";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import OptionModal from "../../components/modal/Option";
import ExportModal from "../../components/modal/Export";
// import Loading from "../../components/misc/Loading";
// import DeleteUser from "../../components/modal/DeleteUser";
import {
  Auth,
  TeachingPlan,
  DirectorDare,
  StudentDare,
  TeacherDare,
  Survey,
  // Transection,
} from "../../models";

const defaultValue = "ทั้งหมด";

export default {
  mixins: [reportMixin],

  components: {
    // vSelect,
    PageContent,
    PageTitle,
    ReportSurveyTable,
    // SurveyChart,
    FilterMaster,
    FilterFiscalYearTerm,
    // DownloadExcel,
    // CreateOrUpdateClass,
    FilterDateBetween,
    OptionModal,
    ExportModal,
    // Loading,
  },

  data() {
    return {
      defaultValue,
      keyword: "",
      // terms: ["1", "2"],
      // planStatuses: [defaultValue, "สำเร็จ", "ไม่สำเร็จ"],
      // isPlan: defaultValue,
      // fiscalYearFrom: "",
      // termFrom: "",
      // fiscalYearTo: "",
      // termTo: "",
      // survey_answers: [],
      formfilter: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },
      deleteData: {},
      editData: {},
      isFetching: false,
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      exportFields: {
        หน่วย: "m_headquarters_name",
        จังหวัด: "m_division_name",
        สถานี: "m_station_name",
        "ยศ ชื่อ-นามสกุล": "user_name",
        // ตำแหน่ง: "position_name",
        โรงเรียน: "school",
        // อำเภอ: "amphur",
        // จังหวัด: "province",
        ห้องเรียน: "classRoom",
        ปีการศึกษา: "yearTerm",
        จำนวนนักเรียน: "actual_student",
        // ผลการบันทึกการสอน: "isPlan",
        // วันที่สร้าง: "created_at",
        // วันที่อัพเดตล่าสุด: "updated_at",
      },
      // sortCol: "user_name",
      colKey: "ยศ ชื่อ-นามสกุล",
      mergedCol: [],

      showExportOption: false,

      exportOptions: [
        { value: "overview", label: "นำออกรายงานภาพรวม" },
        { value: "person", label: "นำออกรายงานรายคน" },
      ],

      typeName: {
        person: "รายคน",
        overview: "ภาพรวม",
      },

      enable: false,
      tableParams: {},
    };
  },

  watch: {
    // tableParams: {
    //   deep: true,
    //   handler: "ontableParamsChanged",
    // },

    "$route.path": {
      handler: "onRouteChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    surveyId() {
      return parseInt(this.$route.params.surveyId);
    },

    survey() {
      return Survey.find(this.surveyId);
    },

    surveyPageTitle() {
      return this.survey ? `รายงาน${this.survey.survey_title}` : "";
    },

    fields() {
      const fields = [
        // { key: "order", label: "ลำดับ" },
        {
          key: "m_headquarters_name",
          label: "ภาค",
          sortable: true,
          thStyle: {
            minWidth: "100px",
          },
        },
        {
          key: "m_division_name",
          label: "จังหวัด",
          sortable: true,
          thStyle: {
            minWidth: "125px",
          },
        },
        {
          key: "m_station_name",
          label: "สถานี",
          sortable: true,
          thStyle: {
            minWidth: "150px",
          },
        },
        {
          key: "user_name",
          label: "ยศ ชื่อ-นามสกุล",
          sortable: true,
          thStyle: {
            minWidth: "200px",
          },
        },
        // {
        //   key: "position_name",
        //   label: "ตำแหน่ง",
        //   sortable: true,
        //   thStyle: {
        //     minWidth: "150px",
        //   },
        // },
        {
          key: "school",
          label: "โรงเรียน",
          sortable: true,
          thStyle: {
            minWidth: "200px",
          },
        },
        // { key: "amphur", label: "อำเภอ" },
        // { key: "province", label: "จังหวัด" },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "actual_student", label: "จำนวนนักเรียน", sortable: true },
        // { key: "actual_student1", label: "จำนวนนักเรียน", sortable: true },
        // { key: "actual_student2", label: "จำนวนนักเรียน", sortable: true },
        // { key: "actual_student3", label: "จำนวนนักเรียน", sortable: true },
        // { key: "isPlan", label: "ผลการบันทึกการสอน", sortable: true },
        // { key: "seeDetail", label: "ข้อมูล", class: "text-center" },
      ];

      return fields;
    },

    // tableParams() {
    //   const { formfilter, filterDateBetween: dateParams } = this;

    //   return {
    //     ...formfilter,
    //     ...this.getFilterTermYear(),
    //     ...dateParams,
    //   };
    // },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);

      const { formfilter, filterDateBetween: dateParams } = this;

      this.enable = false;

      this.tableParams = {
        ...formfilter,
        ...this.getFilterTermYear(),
        ...dateParams,
      };

      this.enable = true;
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetchExport() {
      let data = [];

      this.isExporting = true;

      try {
        data = await this.$refs.table.fetch({ downloadAll: true });
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    exportByType(type) {
      let wb;

      if (type === "person") {
        let { tableData } = this.$refs.table;

        wb = this.exportSheet(tableData, this.exportFields, "ครูแดร์ (รายคน)");
      }

      if (type === "overview") {
        let { overviewData, overviewFields } = this.$refs.table;

        const formatExportedOverviewFields = overviewFields.reduce(
          (acc, overviewField) => {
            this.$set(acc, overviewField.label, overviewField.key);

            return acc;
          },
          {}
        );

        wb = this.exportSheet(
          overviewData,
          formatExportedOverviewFields,
          "ภาพรวม"
        );
      }

      return wb;
    },

    async exportReport(type) {
      const wb = this.exportByType(type);

      /* bookType can be any supported output type */
      const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

      const wbout = XLSX.write(wb, wopts);

      /* the saveAs call downloads a file on the local machine */
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        `รายงาน${this.survey.survey_title}-${this.typeName[type]}.xlsx`
      );
    },

    exportSheet(data, selectedExportFields, pageName) {
      let hasRating = false,
        additionalExportedFields = {},
        questionFieldsLength = {};

      const { questionFields = [], questionChoiceFields = [] } =
        this.$refs.table;

      if (questionFields.length) {
        questionFields.forEach((questionField) => {
          if (questionField.questionType === "choice") {
            const filteredQuestionChoices = questionChoiceFields.filter(
              (questionChoiceField) =>
                questionChoiceField.questionId == questionField.questionId
            );

            let choiceColumnWidth = questionField.label.length / 2;
            let singleChoiceColumnWidth =
              choiceColumnWidth / filteredQuestionChoices.length;

            const totalChoiceLabelWidth = filteredQuestionChoices.reduce(
              (acc, filteredQuestionChoice) => {
                acc += filteredQuestionChoice.label.length;

                return acc;
              },
              0
            );

            filteredQuestionChoices.forEach((questionChoiceField) => {
              this.$set(
                additionalExportedFields,
                questionChoiceField.label,
                questionChoiceField.key
              );

              this.$set(
                questionFieldsLength,
                questionChoiceField.label,
                choiceColumnWidth > totalChoiceLabelWidth
                  ? singleChoiceColumnWidth
                  : questionChoiceField.label.length
              );
            });
          } else {
            let fieldLength = 25;

            this.$set(
              additionalExportedFields,
              questionField.label,
              questionField.key
            );

            if (questionField.questionType === "rating") {
              hasRating = true;
              fieldLength = Math.floor(questionField.label.length / 2);
            }

            this.$set(questionFieldsLength, questionField.label, fieldLength);
          }
        });
      }

      let exportFields = {
        ...selectedExportFields,
        ...additionalExportedFields,
      };

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      let questionHeaders = {};

      let latestChoiceIndex, latestQuestionIndex;

      const surveyTopicFields = questionFields.reduce((acc, questionField) => {
        if (questionField.questionType === "choice") {
          const filteredQuestionChoices = questionChoiceFields.filter(
            (questionChoiceField) =>
              questionChoiceField.questionId == questionField.questionId
          );

          filteredQuestionChoices.forEach((choice) => {
            let index = sheetHeader.indexOf(choice.label, latestChoiceIndex);

            if (index !== -1) {
              latestChoiceIndex = index;
              index += 1;

              if (!questionHeaders[questionField.label]) {
                this.$set(questionHeaders, questionField.label, {
                  start: index,
                  end: index + filteredQuestionChoices.length - 1,
                });
              }

              if (questionField.surveyTopicId) {
                const topicLabel = `${questionField.surveyTopic.survey_topic_no}. ${questionField.surveyTopic.survey_topic_title}`;

                if (!acc[topicLabel]) {
                  this.$set(acc, topicLabel, {
                    start: index,
                    end: index,
                  });
                } else {
                  if (index < acc[topicLabel].start) {
                    this.$set(acc[topicLabel], "start", index);
                  } else if (index > acc[topicLabel].end) {
                    this.$set(acc[topicLabel], "end", index);
                  }
                }
              }
            }
          });
        } else {
          let index = sheetHeader.indexOf(
            questionField.label,
            latestQuestionIndex
          );

          if (index !== -1) {
            latestQuestionIndex = index;

            index += 1;

            if (questionField.surveyTopicId) {
              const topicLabel = `${questionField.surveyTopic.survey_topic_no}. ${questionField.surveyTopic.survey_topic_title}`;

              if (!acc[topicLabel]) {
                this.$set(acc, topicLabel, {
                  start: index,
                  end: index,
                });
              } else {
                if (index < acc[topicLabel].start) {
                  this.$set(acc[topicLabel], "start", index);
                } else if (index > acc[topicLabel].end) {
                  this.$set(acc[topicLabel], "end", index);
                }
              }
            }
          }
        }

        return acc;
      }, {});

      data = data.map((record, index) => {
        const res = {
          ลำดับ: index + 1,
        };

        for (const key in record) {
          if (
            Object.hasOwnProperty.call(record, key) &&
            `${key}`.indexOf("question_") !== -1
          ) {
            let questionValue = record[key];

            if (
              typeof questionValue === "string" ||
              questionValue instanceof String
            ) {
              const splitttedValue = `${questionValue}`
                .split(/\n/gi)
                .filter(Boolean);

              if (splitttedValue.length) {
                questionValue = splitttedValue
                  .map((value) => `${value}`)
                  .join("\n");
              }

              this.$set(record, key, questionValue);
            }
          }
        }

        header.forEach((col) => {
          res[col] = record[exportFields[col]];
        });

        return res;
      });

      const mockRecord = sheetHeader.reduce((acc, sheader) => {
        this.$set(acc, sheader, sheader);

        return acc;
      }, {});

      let headerData = [
        sheetHeader.reduce((acc, sheader) => {
          this.$set(acc, sheader, "");

          return acc;
        }, {}),
      ];

      let startOrder = 0,
        startRow = 1;

      if (Object.keys(surveyTopicFields).length) {
        headerData.push(mockRecord);
        startOrder -= 1;
        startRow += 1;
      }

      if (Object.keys(questionHeaders).length) {
        headerData.push(mockRecord);
        startOrder -= 1;
        startRow += 1;
      }

      const recordStartRow = startRow + 1;

      data = [...headerData, ...data];

      let ws = XLSX.utils.json_to_sheet(data, {
        header: sheetHeader,
      });

      ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        orderCol: "A",
        startOrder,
      });

      this.$set(ws[`A${startRow}`], "v", "");

      this.$set(ws[`A${recordStartRow}`], "v", "ลำดับ");

      ws = this.$_reportMixin_setMergeCell(ws, this.survey.survey_title, {
        startRow: 1,
        endRow: 1,
        startCol: 1,
        endCol: 11,
        style: {
          font: {
            sz: 14,
            bold: true,
          },

          alignment: {
            horizontal: "center",
          },
        },
      });

      ws = this.$_reportMixin_setMergeCell(ws, "", {
        startRow: 1,
        endRow: 1,
        startCol: 12,
        endCol: sheetHeader.length,
        style: {
          font: {
            sz: 14,
            bold: true,
          },

          alignment: {
            horizontal: "center",
          },
        },
      });

      this.$set(ws, "!rows", [{}]);

      const surveyTopicFieldKeys = Object.keys(surveyTopicFields);
      const questionHeaderKeys = Object.keys(questionHeaders);

      if (surveyTopicFieldKeys.length) {
        for (const surveyTopic in surveyTopicFields) {
          if (Object.hasOwnProperty.call(surveyTopicFields, surveyTopic)) {
            const surveyTopicField = surveyTopicFields[surveyTopic];

            ws = this.$_reportMixin_setMergeCell(ws, surveyTopic, {
              startRow: 2,
              endRow: 2,
              startCol: surveyTopicField.start,
              endCol: surveyTopicField.end,
              style: {
                font: {
                  bold: true,
                },
                alignment: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            });
          }
        }

        ws["!rows"].push({});
      }

      if (questionHeaderKeys.length) {
        let maxQuestionHeaderLength = 0;

        const targetRow = surveyTopicFieldKeys.length ? 3 : 2;

        for (const questionHeaderLabel in questionHeaders) {
          if (
            Object.hasOwnProperty.call(questionHeaders, questionHeaderLabel)
          ) {
            const questionHeader = questionHeaders[questionHeaderLabel];
            const questionHeaderLabelLength = questionHeaderLabel.length;

            // console.log(targetRow, questionHeaderLabel, questionHeader);

            if (maxQuestionHeaderLength < questionHeaderLabelLength) {
              maxQuestionHeaderLength = questionHeaderLabelLength;
            }

            ws = this.$_reportMixin_setMergeCell(ws, questionHeaderLabel, {
              startRow: targetRow,
              endRow: targetRow,
              startCol: questionHeader.start,
              endCol: questionHeader.end,
              style: {
                font: {
                  bold: true,
                },
                alignment: {
                  vertical: "top",
                  wrapText: true,
                },
              },
            });
          }
        }

        ws["!rows"].push({ hpt: maxQuestionHeaderLength / 2.5 });
      }

      questionFields.forEach((questionField) => {
        if (questionField.questionType != "choice") {
          ws = this.$_reportMixin_setTextWrap(
            ws,
            questionField.label,
            sheetHeader.indexOf(questionField.label),
            { headerRow: recordStartRow }
          );
        }
      });

      if (hasRating) {
        ws = this.$_reportMixin_addRating(ws, {
          rating: {
            ดีที่สุด: 5,
            ดีมาก: 4,
            ดี: 3,
            ปานกลาง: 2,
            พอใช้: 1,
          },
          row: recordStartRow,
          offset: 1,
        });
      }

      let { startDate, endDate } = this.filterDateBetween;

      if (startDate || endDate) {
        if (startDate) {
          startDate = formatBuddhistBirthDate(new Date(startDate));
        }

        if (endDate) {
          endDate = formatBuddhistBirthDate(new Date(endDate));
        }

        ws = this.$_reportMixin_addDate(ws, {
          startDate,
          endDate,
          row: recordStartRow,
        });
      }

      ws = this.$_reportMixin_setWidthColumn(
        ws,
        sheetHeader,
        data,
        questionFieldsLength
      );

      XLSX.utils.book_append_sheet(wb, ws, pageName);

      return wb;
    },

    exportOverview(data) {
      let exportFields = { ...this.exportFields };

      // if (this.formfilter.mStationId) {
      //   this.$delete(exportFields, "สถานี");
      // }

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      let groupedData = {};

      if (this.formfilter.mStationId) {
        groupedData = groupBy(data, "station");
      } else {
        groupedData = groupBy(data, "division");
      }

      for (const groupName in groupedData) {
        if (Object.hasOwnProperty.call(groupedData, groupName)) {
          let groupRecords = groupedData[groupName];

          if (groupRecords && groupRecords.length) {
            groupRecords = groupRecords.map((record, index) => {
              const res = {
                ลำดับ: index + 1,
              };

              header.forEach((col) => {
                res[col] = record[exportFields[col]];
              });

              return res;
            });
          }
          // console.log("groupRecords", groupRecords);

          let ws = XLSX.utils.json_to_sheet(groupRecords, {
            header: sheetHeader,
          });

          ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
            orderCol: "A",
          });

          let { startDate, endDate } = this.filterDateBetween;

          if (startDate || endDate) {
            if (startDate) {
              startDate = formatBuddhistBirthDate(new Date(startDate));
            }

            if (endDate) {
              endDate = formatBuddhistBirthDate(new Date(endDate));
            }

            ws = this.$_reportMixin_addDate(ws, { startDate, endDate });
          }

          ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, groupRecords);

          // console.log(ws);

          XLSX.utils.book_append_sheet(wb, ws, groupName);
        }
      }

      // data = data.map((record, index) => {
      //   const res = {
      //     ลำดับ: index + 1,
      //   };

      //   header.forEach((col) => {
      //     res[col] = record[exportFields[col]];
      //   });

      //   return res;
      // });

      // let ws = XLSX.utils.json_to_sheet(data, {
      //   header: sheetHeader,
      // });

      // ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
      //   orderCol: "A",
      // });

      // ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

      // XLSX.utils.book_append_sheet(wb, ws, "สถานะแผนการสอน");

      return wb;
    },

    async onSeeDetail(item) {
      this.editData = { ...item };

      if (item.m_station) {
        this.$set(this.editData, "mDivisionId", item.m_station.mDivisionId);

        if (item.m_station.m_division) {
          this.$set(
            this.editData,
            "mHeadquarterId",
            item.m_station.m_division.mHeadquarterId
          );
        }
      }

      if (item.m_school) {
        this.$set(this.editData, "mProvinceId", item.m_school.mProvinceId);
        this.$set(this.editData, "mAmphurId", item.m_school.mAmphurId);
      }

      const [
        teachingPlanPromise,
        directorDarePromise,
        studentDarePromise,
        teacherDarePromise,
      ] = await Promise.all([
        TeachingPlan.api().findAll({ teachingDareId: item.id }),
        DirectorDare.api().findAll({ teachingDareId: item.id }),
        StudentDare.api().findAll({ teachingDareId: item.id }),
        TeacherDare.api().findAll({ teachingDareId: item.id }),
      ]);

      const { data: teachingPlanData = [] } = teachingPlanPromise.response.data;

      if (teachingPlanData.length) {
        this.$set(
          this.editData,
          "teaching_plans",
          sortBy(teachingPlanData, "createdAt").map(
            ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
              note,
              teaching_actual_imgs = [],
            }) => ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
              note,
              teaching_actual_imgs,
            })
          )
        );
      }

      const { data: directorDareData = [] } = directorDarePromise.response.data;

      if (directorDareData.length) {
        this.$set(
          this.editData,
          "director_dares",
          sortBy(directorDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      const { data: studentDareData = [] } = studentDarePromise.response.data;

      if (studentDareData.length) {
        this.$set(
          this.editData,
          "student_dares",
          sortBy(studentDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      const { data: teacherDareData = [] } = teacherDarePromise.response.data;

      if (teacherDareData.length) {
        this.$set(
          this.editData,
          "teacher_dares",
          sortBy(teacherDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      this.showModal = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },

    onInput(value, dataName) {
      if (!value) {
        this.$set(this, dataName, this.defaultValue);
      }
    },

    // async fetch() {
    //   let promise;
    //   let params = {};
    //   this.survey_answers = [];

    //   const {
    //     mHeadquarterId = null,
    //     mDivisionId = null,
    //     mStationId = null,
    //     mSchoolId = null,
    //   } = this.formfilter;

    //   const { startDate, endDate } = this.filterDateBetween;

    //   this.noData = false;
    //   this.isFetching = true;

    //   this.$set(params, "survey_title", "แบบประเมินผล(ผู้ปกครอง)");

    //   if (mHeadquarterId) {
    //     this.$set(params, "mHeadquarterId", mHeadquarterId);
    //   }

    //   if (mDivisionId) {
    //     this.$set(params, "mDivisionId", mDivisionId);
    //   }

    //   if (mStationId) {
    //     this.$set(params, "mStationId", mStationId);
    //   }

    //   if (mSchoolId) {
    //     this.$set(params, "mSchoolId", mSchoolId);
    //   }

    //   if (startDate) {
    //     this.$set(params, "startDate", startDate);
    //   }

    //   if (endDate) {
    //     this.$set(params, "endDate", endDate);
    //   }

    //   try {
    //     promise = await Survey.api().getDashboard({
    //       survey_id: this.surveyId,
    //       ...params,
    //       ...this.getFilterTermYear(),
    //     });

    //     const { data = [] } = promise.response.data;

    //     if (data && data.length > 0) {
    //       for (let index = 0; index < data.length; index++) {
    //         const element = data[index];
    //         let { questions } = element;

    //         let tmp_questions = await questions.filter(
    //           (question) => question.question_type !== "text"
    //         );

    //         tmp_questions.sort(function (a, b) {
    //           var question_no_A = a.question_no,
    //             question_no_B = b.question_no;
    //           if (question_no_A < question_no_B) return -1;
    //           if (question_no_A > question_no_B) return 1;
    //           return 0;
    //         });

    //         let tmpQuestionsAll;

    //         let newQuestions = tmp_questions.filter(function (question) {
    //           return question.surveyTopicId == null;
    //         });

    //         let tmpQuestions = mapKeys(newQuestions, function (o) {
    //           return o.question_no;
    //         });

    //         let newQuestionsHaveSurveyTopic = tmp_questions.filter(function (
    //           question
    //         ) {
    //           return question.surveyTopicId !== null;
    //         });

    //         if (newQuestionsHaveSurveyTopic) {
    //           let groupBy_newQuestionsHaveSurveyTopic = chain(
    //             newQuestionsHaveSurveyTopic
    //           )
    //             .groupBy("survey_topic.survey_topic_no")
    //             .map((value, key) => ({ survey_topic: key, questions: value }))
    //             .value();

    //           let tmpSurveyTopics = mapKeys(
    //             groupBy_newQuestionsHaveSurveyTopic,
    //             function (o) {
    //               return o.survey_topic;
    //             }
    //           );

    //           tmpQuestionsAll = { ...tmpQuestions, ...tmpSurveyTopics };
    //         } else {
    //           tmpQuestionsAll = { ...tmpQuestions };
    //         }

    //         this.survey_answers.push({
    //           ...element,
    //           questions: tmpQuestionsAll,
    //         });
    //       }
    //     } else {
    //       this.noData = true;
    //     }
    //   } catch (error) {
    //     this.$toast.error(
    //       "ไม่สามารถดึงสรุปข้อมูลแบบประเมินได้ กรุณาลองใหม่อีกครั้ง"
    //     );
    //   } finally {
    //     this.isFetching = false;
    //   }

    //   return promise;
    // },

    async onRouteChanged() {
      this.enable = false;

      this.formfilter = {};

      this.filterDateBetween = {
        startDate: null,
        endDate: null,
      };

      this.formFiscalYearTerm = {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      };

      this.keyword = "";
      this.deleteData = {};
      this.editData = {};
      this.isFetching = false;
      this.isExporting = false;
      this.showModal = false;
      this.showModalDelete = false;

      // await this.$refs.table.refresh();
    },
  },

  created() {
    // this.fetch = debounce(this.fetch, 500);
  },

  mounted() {
    // this.fetch();
  },
};
</script>
